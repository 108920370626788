import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'
import moment from 'moment'
import consumer_apply_approveList from '@/lib/data-service/haolv-default/consumer_apply_approveList'
import consumer_apply_myApplyList from '@/lib/data-service/haolv-default/consumer_apply_myApplyList'
import consumer_notice_list from "@/lib/data-service/haolv-default/consumer_notice_list";
import get_consumer_menu_getUserMenu from "@/lib/common-service/get_consumer_menu_getUserMenu";
import consumer_web_company_returnHZRegisStatus from '@/lib/data-service/haolv-default/consumer_web_company_returnHZRegisStatus'
import consumer_web_company_returnHZApplyStatus from '@/lib/data-service/haolv-default/consumer_web_company_returnHZApplyStatus'
import {hideTrainOpenCompanyIdList} from "@/lib/other";
import consumer_apply_workflowMyApplyList from "@/lib/data-service/haolv-default/consumer_apply_workflowMyApplyList";
import consumer_apply_workflowApproveList from "@/lib/data-service/haolv-default/consumer_apply_workflowApproveList";

export default {
    data() {
        return {
            hideTrainOpenCompanyIdList: hideTrainOpenCompanyIdList,
            activeName: '1',
            userInfo: null,
            companyId: '',
            approveList: [], // 待我审批列表
            myApplyList: [], // 我的出差列表
            noticeList: [], // 通知列表
            dialogInfo: {
                noticeTitle: '',
                noticeContent: '',
            },
            dialogVisibleNotice: false,
            regisType: 3, // 当前企业状态 1：显示注册入口　2：显示补全企业信息入口 3：不显示入口
        }
    },
    components: {},
    created() {

    },
    mounted() {
    },
    async activated() {
        const tab = this.$route.meta.tab
        if (tab) {
            this.activeName = tab.toString()
        }
        await this.$store.state.workflowDefer.promise;
        this.getNoticeList()
        this.getMyApproveList()
        this.getMyApplyList()
        this.getEvectionAllInfo() // 获取信息
    },
    deactivated() { },
    destroyed() { },
    watch: {},
    computed: {},
    filters: {
        getMonthAndDay(val) {
            if (val === null) {
                return '-'
            }
            let day = moment(val).format('MM-DD')
            return day
        }
    },
    methods: {
        getNoticeList() {
            consumer_notice_list().then(data => {
                this.noticeList = data.list
            })
        },
        showDetail(row) {
            this.dialogInfo = {
                noticeTitle: row.noticeTitle,
                noticeContent: row.noticeContent,
            }
            this.$nextTick(() => {
                this.dialogVisibleNotice = true
            })
        },
        handleMoreNotice() {
            let canPass = false;
            get_consumer_menu_getUserMenu().then(res => {
                let userMenu = res.datas
                userMenu.forEach((value) => {
                    if (value.id === 7) {
                        value.menus.forEach((value1) => {
                            if (value1.id === 79 && value1.menus.length > 0) {
                                canPass = true;
                            }
                        });
                    }
                });
                if (canPass) {
                    this.$router.push("/admin/notice");
                } else {
                    // 弹窗 您当前没有指定权限，请联系管理员开通此权限
                    this.$alert("您当前没有指定权限，请联系管理员开通此权限", "温馨提示", {
                        confirmButtonText: "确定",
                        callback: (action) => {},
                    });
                }
            })
        },
        travelMore() {
            let canPass = false;
            get_consumer_menu_getUserMenu().then(res => {
                let userMenu = res.datas
                userMenu.forEach((value) => {
                    if (value.id === 3) {
                        value.menus.forEach((value1) => {
                            if (value1.id === 31 && value1.menus.length > 0) {
                                canPass = true;
                            }
                        });
                    }
                });
                if (canPass) {
                    this.$router.push({
                        name: "admin-my-apply-manage-apply",
                    });
                } else {
                    // 弹窗 您当前没有指定权限，请联系管理员开通此权限
                    this.$alert("您当前没有指定权限，请联系管理员开通此权限", "温馨提示", {
                        confirmButtonText: "确定",
                        callback: (action) => {},
                    });
                }
            })
        },
        moreApproval() {
            let canPass = false;
            get_consumer_menu_getUserMenu().then(res => {
                let userMenu = res.datas
                userMenu.forEach((value) => {
                    if (value.id === 3) {
                        value.menus.forEach((value1) => {
                            if (value1.id === 32 && value1.menus.length > 0) {
                                canPass = true;
                            }
                        });
                    }
                });
                if (canPass) {
                    this.$router.push({
                        name: "admin-my-apply-manage-no-approve",
                    });
                } else {
                    // 弹窗 您当前没有指定权限，请联系管理员开通此权限
                    this.$alert("您当前没有指定权限，请联系管理员开通此权限", "温馨提示", {
                        confirmButtonText: "确定",
                        callback: (action) => {},
                    });
                }
            })
        },
        // 获取待我审批
        getMyApproveList() {
            let params = {
                pageSize: 8,
                currentPage: 1,
                approveStatus: 1,
            }
            const approveApi = this.$store.state.workflow === '1' ? consumer_apply_workflowApproveList(params) : consumer_apply_approveList(params);
            approveApi.then(res => {
                this.approveList = res.datas.list
            })
        },
        // 获取我到出差
        getMyApplyList() {
            let params = {
                pageSize: 8,
                currentPage: 1
            }
            let applyApi = this.$store.state.workflow === '1' ? consumer_apply_workflowMyApplyList(params) : consumer_apply_myApplyList(params);
            applyApi.then(res => {
                this.myApplyList = res.datas.list
            })
        },
        async getEvectionAllInfo() {
            let res = await get_user_info()
            this.userInfo = res.datas;
            this.companyId = res.datas.companyId;
            if (this.userInfo.userType === 2) {
                // 调用大客户状态
                consumer_web_company_returnHZRegisStatus().then(res => {
                    this.regisType = res.datas.regisType
                })
            }
        },
        handleClick() {
            switch (this.activeName) {
                case '1':
                    this.$router.push({
                        name: 'admin-travel-book-home-flight'
                    })
                    break
                case '2':
                    this.$router.push({
                        name: 'admin-travel-book-home-train'
                    })
                    break
                case '3':
                    this.$router.push({
                        name: 'admin-travel-book-home-hotel'
                    })
                    break
                case '4':
                    this.$router.push({
                        name: 'admin-travel-book-home-car'
                    })
                    break
                default:

            }
        },
        toOrder() {
            this.$router.push({
                name: 'admin-travel-reserve'
            })
        },
        goInfo(val) {
            // applyId&applyNo=1796419879386624000&appType=1&selectType=1&approveStatus=1&type=info&approveType=1&lastMenu=adminMyApply
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: val.id,
                    applyNo: val.applyNo,
                    approveType: val.approveType,
                    approveStatus: val.approveStatus,
                    selectType: 1,
                    appType: 1,
                    type: 'info',
                    lastMenu: 'adminMyApply',
                }
            })
        },
        goApprovalInfo(val) {
            // applyId&applyNo=1795328742416715776&appType=2&approveStatus=1&listApproveStatus=1&type=audit&approveType=1
            this.$router.push({
                name: 'admin-my-apply-manage-noApprove-detail',
                query: {
                    applyId: val.id,
                    applyNo: val.applyNo,
                    appType: 2,
                    approveStatus: val.approveStatus,
                    listApproveStatus: 1,
                    type: 'audit',
                    approveType: val.approveType
                }
            })
        },
        toHotelVipRegister() {
            if (this.regisType === 1) {
                consumer_web_company_returnHZApplyStatus().then(res => {
                    if (res.datas.applyStatus === 1) {
                        this.$message({
                            type: "warning",
                            message: '您的企业已有申请注册成为华住集团大客户的记录，请耐心等待审核结果'
                        })
                    } else {
                        this.$router.push({
                            name: 'admin-hotel-vip-register'
                        })
                    }
                })

            } else if (this.regisType === 2) {
                this.$router.push({
                    name: 'admin-hotel-vip-info-edit'
                })
            }
        },
    }
}
