/**
 * 1. 中顺洁柔（云浮）纸业有限公司  276
 * 2. 中顺洁柔纸业股份有限公司  217
 * 3. 中顺洁柔纸业股份有限公司唐山分公司  279
 * 4. 中顺洁柔（四川）纸业有限公司  278
 * 5. 中山市中顺商贸有限公司  282
 * 6. 浙江中顺纸业有限公司  281
 * 7. 成都中顺纸业有限公司  280
 * 8. 江门中顺纸业有限公司  277
 * 9. 中顺洁柔（湖北）纸业有限公司  275
 * **/
const hidePolicyLabelCompanyIdList = [217,274,275,276,277,278,279,280, 283, 282, 281];
const hideTrainOpenCompanyIdList = [276, 217, 279, 278, 282, 281, 280, 277, 275];
const hideEvectionTypeChangeTabCompanyIdList = [302]; // 302 法巴农银 隐藏因私预订
const flightListSortByPriceCompanyIdList = [290]; // 290 广东志特新材料集团有限公司 机票排序由票价低开始
export {
    hidePolicyLabelCompanyIdList,
    hideTrainOpenCompanyIdList,
    hideEvectionTypeChangeTabCompanyIdList,
    flightListSortByPriceCompanyIdList
}
